* {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow: hidden;
  font-family: Helvetica, Arial, sans-serif;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

#welcome-layer {
  width: 100vw;
  height: 100vh;
  color: white;
  text-align: center;
  flex-direction: column;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: start;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../static/images/lightbulb_background_vertical2.png");
  background-position: center;
  background-color: black;
  opacity: 0.95;
}

#text-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  height: 40vh;
  width: 80vw;
  margin-top: 5%;
  justify-content: flex-start;
}

#text-headline {
  font-size: 18vw;
  margin-top: 4.5vh;
  margin-bottom: 7vh;
}

#text-subtitle {
  font-size: 5vw;
}

#navigation {
  z-index: 2;
  position: fixed;
  width: 100vw;
}

.close {
  float: right;
  padding: 2px 5px;
}

.close:hover {
  float: right;
  padding: 2px 5px;
  background: #ccc;
  color: #d3d2d2;
  cursor: pointer;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 1vw;
  background: rgba(189, 195, 199, 0.15);
  text-align: center;
}

#nav-projects,
#nav-about,
#nav-contact,
#nav-credits,
#nav-instructions,
#nav-back {
  display: inline;
  color: white;
  font-size: 3vw;
  margin-right: 4vw;
  margin-left: 4vw;
  font-weight: 400;
}

#nav-projects:hover,
#nav-about:hover,
#nav-contact:hover,
#nav-credits:hover,
#nav-back:hover,
#nav-instructions:hover {
  cursor: pointer;
  color: #d3d2d2;
}

/* #nav-back {
  display: none;
} */

#mobile-text,
#instructions-text {
  font-size: 5vw;
  margin-top: 1em;
  margin-bottom: 1em;
  position: relative;
  text-align: center;
}

#mobile-container {
  z-index: 12;
  height: 27em;
  width: 80vw;
  display: block;
  position: fixed;
  text-align: center;
  margin: 8vh 10vw;
  color: white;
  background: rgba(53, 54, 55, 0.98);
}

#instructions-headline {
  font-size: 6vw;
  margin-bottom: 2.5vh;
  margin-top: 1em;
}

#instructions-container {
  z-index: 12;
  height: 45em;
  width: 80vw;
  display: block;
  position: fixed;
  text-align: center;
  margin: 8vh 10vw;
  color: white;
  background: rgba(53, 54, 55, 0.9);
}

#switch-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  font-size: 1.5vw;
  margin-top: 31.5vh;
  margin-left: 88vw;
  width: 10vw;
  height: 25vh;
}

@media only screen and (min-width: 768px) {
  #welcome-layer {
    align-items: flex-end;
    justify-content: center;
    background-image: url("../static/images/lightbulb_background_cut2.png");
  }

  #text-container {
    height: 60vh;
    width: 45vw;
    margin-bottom: 3em;
    margin-top: 0;
    justify-content: center;
  }

  #text-headline {
    font-size: 7vw;
    margin-bottom: 2.5vh;
    margin-top: 0px;
  }

  #text-subtitle {
    font-size: 2vw;
  }

  #instructions-headline {
    font-size: 2.5vw;
    margin-bottom: 2.5vh;
    margin-top: 0px;
  }

  #instructions-text {
    font-size: 1.5vw;
  }

  #instructions-container {
    z-index: 12;
    height: 60vh;
    display: block;
    position: fixed;
    text-align: center;
    margin: 17vh 25vw;
    width: 60vw;
    color: white;
    background: rgba(53, 54, 55, 0.9);
  }

  #switch-container {
    font-size: 0.5vw;
    margin-top: 0px;
    margin-left: 0px;
    height: 20vh;
    width: 45vw;
  }

  #mobile-container {
    display: none;
  }

  ul {
    text-align: left;
  }

  #nav-projects,
  #nav-about,
  #nav-contact,
  #nav-credits {
    font-size: 1vw;
    font-weight: 700;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
    margin-right: 1.5vw;
    margin-left: 1.5vw;
  }

  #nav-back {
    font-size: 1.2vw;
    margin-left: 48.5vw;
    margin-right: 1.5vw;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
    display: inline;
    font-family: Helvetica, Arial, sans-serif;
    color: white;
    font-weight: 700;
  }
  #nav-instructions {
    font-size: 1.2vw;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
    display: inline;
    font-family: Helvetica, Arial, sans-serif;
    color: white;
    font-weight: 700;
  }
}

.switch {
  display: inline-block;
  margin: 10em 2em;
  position: relative;
  border-radius: 3.5em;
  -webkit-box-shadow: 0 0 0.5em rgba(255, 255, 255, 0.2);
  -moz-box-shadow: 0 0 0.5em rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0.5em rgba(255, 255, 255, 0.2);
}

.switch label {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.switch input {
  display: none;
}

.switch span {
  display: block;
  -webkit-transition: top 0.2s;
  -moz-transition: top 0.2s;
  -ms-transition: top 0.2s;
  -o-transition: top 0.2s;
  transition: top 0.2s;
}

.switch label:hover,
#loading-circle:hover {
  cursor: wait;
}

.switch-border1 {
  border: 0.1em solid #000;
  border-radius: 3.5em;
  -webkit-box-shadow: 0 0.2em rgba(255, 255, 255, 0.2);
  -moz-box-shadow: 0 0.2em rgba(255, 255, 255, 0.2);
  box-shadow: 0 0.2em rgba(255, 255, 255, 0.2);
}

.switch-border2 {
  width: 6.6em;
  height: 12.6em;
  position: relative;
  border: 0.1em solid #323232;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2d2d2d),
    color-stop(0.5, #4b4b4b),
    to(#2d2d2d)
  );
  background-image: -webkit-linear-gradient(left, #2d2d2d, #4b4b4b, #2d2d2d);
  background-image: -moz-linear-gradient(left, #2d2d2d, #4b4b4b, #2d2d2d);
  background-image: -ms-linear-gradient(left, #2d2d2d, #4b4b4b, #2d2d2d);
  background-image: -o-linear-gradient(left, #2d2d2d, #4b4b4b, #2d2d2d);
  background-image: linear-gradient(to right, #2d2d2d, #4b4b4b, #2d2d2d);
  border-radius: 3.4em;
}

.switch-border2:before,
.switch-border2:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.3;
  border-radius: 3.4em;
}

.switch-border2:before {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#000),
    to(rgba(0, 0, 0, 0))
  );
  background: -webkit-linear-gradient(#000, rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(#000, rgba(0, 0, 0, 0));
  background: -ms-linear-gradient(#000, rgba(0, 0, 0, 0));
  background: -o-linear-gradient(#000, rgba(0, 0, 0, 0));
  background: linear-gradient(#000, rgba(0, 0, 0, 0));
}

.switch-border2:after {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0)),
    to(#000)
  );
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0), #000);
  background: -moz-linear-gradient(rgba(0, 0, 0, 0), #000);
  background: -ms-linear-gradient(rgba(0, 0, 0, 0), #000);
  background: -o-linear-gradient(rgba(0, 0, 0, 0), #000);
  background: linear-gradient(rgba(0, 0, 0, 0), #000);
}

.switch-top {
  width: 100%;
  height: 84%;
  position: absolute;
  top: 8%;
  left: 0;
  z-index: 1;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2d2d2d),
    color-stop(0.5, #4b4b4b),
    to(#2d2d2d)
  );
  background-image: -webkit-linear-gradient(left, #2d2d2d, #4b4b4b, #2d2d2d);
  background-image: -moz-linear-gradient(left, #2d2d2d, #4b4b4b, #2d2d2d);
  background-image: -ms-linear-gradient(left, #2d2d2d, #4b4b4b, #2d2d2d);
  background-image: -o-linear-gradient(left, #2d2d2d, #4b4b4b, #2d2d2d);
  background-image: linear-gradient(to right, #2d2d2d, #4b4b4b, #2d2d2d);
  border-radius: 3.4em;
}

.switch-shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  border-radius: 3.4em;
  -webkit-box-shadow: 0 0 2em black inset;
  -moz-box-shadow: 0 0 2em black inset;
  box-shadow: 0 0 2em black inset;
}

.switch-handle-left,
.switch-handle-right {
  content: "";
  display: block;
  width: 3.6em;
  height: 0;
  position: absolute;
  top: 6.6em;
  z-index: 2;
  border-bottom: 4.5em solid #111;
  border-left: 0.7em solid transparent;
  border-right: 0.7em solid transparent;
  border-radius: 0;
}

.switch-handle-left {
  left: 0.8em;
}

.switch-handle-right {
  right: 0.8em;
}

.switch-handle {
  width: 3.6em;
  height: 4.5em;
  position: absolute;
  top: 6.6em;
  left: 1.5em;
  z-index: 3;
  background: #333;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#111),
    color-stop(0.4, #777),
    color-stop(0.5, #888),
    color-stop(0.6, #777),
    to(#111)
  );
  background-image: -webkit-linear-gradient(
    left,
    #111,
    #777 40%,
    #888,
    #777 60%,
    #111
  );
  background-image: -moz-linear-gradient(
    left,
    #111,
    #777 40%,
    #888,
    #777 60%,
    #111
  );
  background-image: -ms-linear-gradient(
    left,
    #111,
    #777 40%,
    #888,
    #777 60%,
    #111
  );
  background-image: -o-linear-gradient(
    left,
    #111,
    #777 40%,
    #888,
    #777 60%,
    #111
  );
  background-image: linear-gradient(
    to right,
    #111,
    #777 40%,
    #888,
    #777 60%,
    #111
  );
  border-radius: 0;
}

.switch-handle-top {
  width: 5em;
  height: 5em;
  position: absolute;
  top: 8.5em;
  left: 0.8em;
  z-index: 4;
  background-color: #555;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#5f5f5f),
    to(#878787)
  );
  background-image: -webkit-linear-gradient(left, #5f5f5f, #878787);
  background-image: -moz-linear-gradient(left, #5f5f5f, #878787);
  background-image: -ms-linear-gradient(left, #5f5f5f, #878787);
  background-image: -o-linear-gradient(left, #5f5f5f, #878787);
  background-image: linear-gradient(to right, #5f5f5f, #878787);
  border-top: 0.2em solid #aeb2b3;
  border-radius: 2.5em;
}

.switch-handle-bottom {
  width: 3.6em;
  height: 3.6em;
  position: absolute;
  top: 4.7em;
  left: 1.5em;
  z-index: 3;
  background: #333;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#111),
    color-stop(0.4, #777),
    color-stop(0.5, #888),
    color-stop(0.6, #777),
    to(#111)
  );
  background-image: -webkit-linear-gradient(
    left,
    #111,
    #777 40%,
    #888,
    #777 60%,
    #111
  );
  background-image: -moz-linear-gradient(
    left,
    #111,
    #777 40%,
    #888,
    #777 60%,
    #111
  );
  background-image: -ms-linear-gradient(
    left,
    #111,
    #777 40%,
    #888,
    #777 60%,
    #111
  );
  background-image: -o-linear-gradient(
    left,
    #111,
    #777 40%,
    #888,
    #777 60%,
    #111
  );
  background-image: linear-gradient(
    to right,
    #111,
    #777 40%,
    #888,
    #777 60%,
    #111
  );
  border-top: 0.2em solid #141414;
  border-radius: 1.8em;
}

.switch-handle-base {
  width: 4.2em;
  height: 4.2em;
  position: absolute;
  top: 3.8em;
  left: 1.2em;
  z-index: 2;
  border-top: 0.2em solid rgba(255, 255, 255, 0.35);
  border-radius: 2.1em;
  -webkit-box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.8) inset;
  -moz-box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.8) inset;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.8) inset;
}

.switch-led {
  position: absolute;
  left: 2em;
  border-radius: 1.4em;
}

.switch-led-border {
  border: 0.2em solid black;
  border-radius: 1.3em;
}

.switch-led-light {
  border-radius: 1.1em;
  -webkit-box-shadow: 0 0 0.5em rgba(255, 255, 255, 0.5) inset;
  -moz-box-shadow: 0 0 0.5em rgba(255, 255, 255, 0.5) inset;
  box-shadow: 0 0 0.5em rgba(255, 255, 255, 0.5) inset;
}

.switch-led-glow {
  width: 2em;
  height: 2em;
  position: relative;
  border-radius: 1em;
}

.switch-led-glow:before {
  content: "";
  display: block;
  width: 0.6em;
  height: 0.6em;
  position: absolute;
  top: 0.3em;
  left: 0.7em;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0.3em;
  -webkit-box-shadow: 0 0 1em rgba(255, 255, 255, 0.75);
  -moz-box-shadow: 0 0 1em rgba(255, 255, 255, 0.75);
  box-shadow: 0 0 1em rgba(255, 255, 255, 0.75);
}

.switch-led-glow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.2;
  filter: alpha(opacity=20);
  border: 1em solid #fff;
  border-color: transparent #fff transparent #fff;
  border-radius: 1em;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.switch-led:after {
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  color: #666;
  font-family: arial, verdana, sans-serif;
  font-weight: bold;
  text-align: center;
  text-shadow: 0 0.1em rgba(0, 0, 0, 0.7);
}

.switch-led-green:after {
  content: "ON";
  bottom: -1.8em;
}

.switch-led-red:after {
  content: "OFF";
  top: -1.8em;
}

.switch-led-green {
  bottom: -5em;
  border-top: 0.1em solid rgba(0, 161, 75, 0.5);
  border-bottom: 0.1em solid rgba(255, 255, 255, 0.25);
  -webkit-box-shadow: 0 0 3em rgb(0, 161, 75);
  -moz-box-shadow: 0 0 3em rgb(0, 161, 75);
  box-shadow: 0 0 3em rgb(0, 161, 75);
}

.switch-led-green .switch-led-light {
  background: rgb(0, 161, 75);
  border: 0.1em solid rgb(0, 104, 56);
}

.switch-led-green .switch-led-glow {
  background: #fff;
  background: rgba(255, 255, 255, 0.4);
  filter: alpha(opacity=40);
}

.switch-led-red {
  top: -5em;
  border-top: 0.1em solid rgba(237, 28, 36, 0.2);
  border-bottom: 0.1em solid rgba(255, 255, 255, 0.25);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.switch-led-red .switch-led-light {
  background: rgb(237, 28, 36);
  border: 0.1em solid rgb(161, 30, 45);
}

.switch-led-red .switch-led-glow {
  background: rgba(255, 255, 255, 0);
  filter: alpha(opacity=0);
}

/* Switch on */

.switch input:checked ~ .switch-handle-left,
.switch input:checked ~ .switch-handle-right {
  top: 1.5em;
  border-bottom: 0;
  border-top: 4.5em solid #111;
}

.switch input:checked ~ .switch-handle {
  top: 1.5em;
}

.switch input:checked ~ .switch-handle-top {
  top: -1em;
  border-top: 0;
  border-bottom: 0.2em solid #aeb2b3;
}

.switch input:checked ~ .switch-handle-bottom {
  top: 4.2em;
  border-top: 0;
  border-bottom: 0.2em solid #141414;
}

.switch input:checked ~ .switch-handle-base {
  top: 4.5em;
  border-top: 0;
  border-bottom: 0.2em solid rgba(255, 255, 255, 0.35);
}

.switch input:checked ~ .switch-led-green {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.switch input:checked ~ .switch-led-green .switch-led-glow {
  background: rgba(255, 255, 255, 0);
  filter: alpha(opacity=0);
}

.switch input:checked ~ .switch-led-red {
  -webkit-box-shadow: 0 0 3em rgb(237, 28, 36);
  -moz-box-shadow: 0 0 3em rgb(237, 28, 36);
  box-shadow: 0 0 3em rgb(237, 28, 36);
}

.switch input:checked ~ .switch-led-red .switch-led-glow {
  background: #fff;
  background: rgba(255, 255, 255, 0.3);
  filter: alpha(opacity=30);
}

.lds-ring {
  display: inline-block;
  position: relative;
  margin-top: 2em;
  width: 6.6em;
  height: 6.6em;
  z-index: 11;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 6.6em;
  height: 6.6em;

  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
